/**
 * It takes in an object with two properties, details and address, and returns a new object with the
 * properties of details and address merged together
 * @param {any} businessData - any - this is the data that is passed in from the API call.
 * @returns An object with the details and address properties of the businessData object.
 */
const uniqueBusiness = (businessData: any): Object => {
  return { ...businessData?.details, ...businessData?.address };
};
/**
 * It takes an object with nested objects and returns an object with only the values of the nested
 * objects
 * @param {any} businessData - any - this is the data that is passed in from the form.
 * @returns An object with the key value pairs of the business data.
 */
export const businessKeyValue = (businessData: any): Object => {
  const oneObject: any = uniqueBusiness(businessData);
  const business = Object.fromEntries(
    Object.entries(oneObject || [])
      .filter((f: any) => f[1] !== null)
      .map((e: any) => {
        return [e[0], e[1].value];
      })
  );
  return business;
};
/**
 * It takes a JSON object and returns a JSON object with the same keys but with different values
 * @param {any} businessData - This is the data that is returned from the API.
 */
const middlewareApiBusiness = (apiData: any): any => {
  const businessData: any = apiData.builderData.business;
  const result: any = {
    businessDetails: {},
  };
  if (businessData === null) {
    return result;
  }
  const { properties } = apiData;
  const oneObject: any = uniqueBusiness(businessData);
  if (oneObject.legalname !== null) {
    const { legalname } = oneObject;
    result.businessDetails.legalname = {
      value: apiData?.legalName || legalname.value,
      validator: 'text',
      readonly: legalname.ro,
      required: legalname.rq,
      visible:
        legalname?.visible === null || legalname?.visible === undefined
          ? false
          : legalname?.visible,
      type: 'text',
      label: properties?.legalname || 'Business Legal Name',
    };
  }
  if (oneObject.dbaname !== null) {
    const { dbaname } = oneObject;
    result.businessDetails.dbaname = {
      value: apiData.dbaName || dbaname.value,
      validator: 'text',
      readonly: dbaname.ro,
      required: dbaname.rq,
      visible:
        dbaname?.visible === null || dbaname?.visible === undefined
          ? false
          : dbaname?.visible,
      type: 'text',
      label: properties?.dbaname || 'Business DBA Name',
      toolTip:
        "This is your fictitious 'doing business as' name that you may have registered to distinguish from your legal name",
    };
  }
  if (oneObject.taxfillname !== null) {
    const { taxfillname } = oneObject;
    result.businessDetails.taxfillname = {
      value: apiData?.taxfillname || taxfillname.value,
      validator: 'text',
      readonly: taxfillname.ro,
      required: taxfillname.rq,
      visible:
        taxfillname?.visible === null || taxfillname?.visible === undefined
          ? false
          : taxfillname?.visible,
      type: 'text',
      label: properties?.taxfillname || 'Tax Filing Name',
      toolTip:
        'The combination of your Tax ID Number and your Tax Filing Name must match your IRS Documents',
    };
  }
  if (oneObject.btype !== null) {
    const { btype } = oneObject;
    result.businessDetails.btype = {
      value:
        apiData?.ownType === 'SELECT' || btype.value.toUpperCase() === 'SELECT'
          ? ''
          : apiData?.ownType || btype.value,
      validator: 'text',
      readonly: btype.ro,
      required: btype.rq,
      visible:
        btype?.visible === null || btype?.visible === undefined
          ? false
          : btype?.visible,
      type: 'selection',
      stype: 'btype',
      label: properties?.btype || 'Business Type',
    };
  }
  if (oneObject.website !== null) {
    const { website } = oneObject;
    result.businessDetails.website = {
      value: apiData?.websiteAddress || website.value,
      validator: 'url',
      readonly: website.ro,
      required: website.rq,
      visible:
        website?.visible === null || website?.visible === undefined
          ? false
          : website?.visible,
      type: 'url',
      label: properties?.website || 'Website',
    };
  }
  if (oneObject.ein !== null) {
    const { ein } = oneObject;
    result.businessDetails.ein = {
      value: apiData?.ein || ein.value,
      validator: 'ein',
      readonly: ein.ro,
      required: ein.rq,
      visible:
        ein?.visible === null || ein?.visible === undefined
          ? false
          : ein?.visible,
      type: 'ein',
      label: properties?.ein || 'Business EIN',
      toolTip: 'Tax ID Number or Social Security Number for Sole Proprietor',
    };
  }
  if (oneObject.license !== null) {
    const { license } = oneObject;
    result.businessDetails.license = {
      value: apiData?.license || license.value,
      validator: 'alphanumeric',
      readonly: license.ro,
      required: license.rq,
      visible:
        license?.visible === null || license?.visible === undefined
          ? false
          : license?.visible,
      type: 'alphanumeric',
      label: properties?.license || 'Business License',
      toolTip: 'ID number for Government Issued Business License',
    };
  }
  if (oneObject.licstate !== null) {
    const { licstate } = oneObject;
    result.businessDetails.licstate = {
      value:
        apiData?.licenseState === 'SELECT' ||
        licstate.value.toUpperCase() === 'SELECT'
          ? ''
          : apiData?.licenseState || licstate.value,
      validator: 'text',
      readonly: licstate.ro,
      required: licstate.rq,
      visible:
        licstate?.visible === null || licstate?.visible === undefined
          ? false
          : licstate?.visible,
      type: 'state',
      label: properties?.licstate || 'License State',
    };
  }
  if (oneObject.startdate) {
    const apiStartDate = apiData?.bStartdate || oneObject.startdate?.value;
    const today = new Date();
    const startdate = new Date(apiStartDate) > today ? today : apiStartDate;
    result.businessDetails.startdate = {
      value: startdate,
      validator: 'date',
      readonly: oneObject.startdate.ro,
      required: oneObject.startdate.rq,
      visible:
        oneObject.startdate?.visible === null ||
        oneObject.startdate?.visible === undefined
          ? false
          : oneObject.startdate?.visible,
      type: 'date',
      label: properties?.startdate || 'Business Start Date',
    };
  }
  if (oneObject.phonenumber !== null) {
    const { phonenumber } = oneObject;
    result.businessDetails.phonenumber = {
      value: apiData?.bPhone || phonenumber.value,
      validator: 'phone',
      readonly: phonenumber.ro,
      required: phonenumber.rq,
      visible:
        phonenumber?.visible === null || phonenumber?.visible === undefined
          ? false
          : phonenumber?.visible,
      type: 'phone',
      label: properties?.phonenumber || 'Phone Number',
      toolTip: 'Direct Phone Number of the owner',
    };
  }
  if (oneObject.faxnumber !== null) {
    const { faxnumber } = oneObject;
    result.businessDetails.faxnumber = {
      value: apiData?.bFax || faxnumber.value,
      validator: 'phone',
      readonly: faxnumber.ro,
      required: faxnumber.rq,
      visible:
        faxnumber?.visible === null || faxnumber?.visible === undefined
          ? false
          : faxnumber?.visible,
      type: 'phone',
      label: properties?.faxnumber || 'Fax Number',
    };
  }
  if (oneObject.baddress !== null) {
    const { baddress } = oneObject;
    result.businessDetails.baddress = {
      value: apiData?.bAddress1 || baddress.value,
      validator: 'text',
      readonly: baddress.ro,
      required: baddress.rq,
      visible:
        baddress?.visible === null || baddress?.visible === undefined
          ? false
          : baddress?.visible,
      type: 'address',
      label: properties?.baddress || 'Business Address',
      toolTip: 'Where the business is located',
    };
  }
  if (oneObject.baddress1 !== null) {
    const { baddress1 } = oneObject;
    result.businessDetails.baddress1 = {
      value: apiData?.bAddress2 || baddress1.value,
      validator: 'text',
      readonly: baddress1.ro,
      required: baddress1.rq,
      visible:
        baddress1?.visible === null || baddress1?.visible === undefined
          ? false
          : baddress1?.visible,
      type: 'text',
      label:
        properties?.baddress1 || 'Address 2 (ex: apt, suite, building, etc.)',
    };
  }
  if (oneObject.bcountry !== null) {
    const { bcountry } = oneObject;
    result.businessDetails.bcountry = {
      value:
        apiData?.bCountry === 'SELECT' ||
        bcountry.value.toUpperCase() === 'SELECT'
          ? ''
          : apiData?.bCountry || bcountry.value,
      validator: 'text',
      readonly: bcountry.ro,
      required: bcountry.rq,
      visible:
        bcountry?.visible === null || bcountry?.visible === undefined
          ? false
          : bcountry?.visible,
      type: 'country',
      label: properties?.bcountry || 'Country',
    };
  }
  if (oneObject.bcity !== null) {
    const { bcity } = oneObject;
    result.businessDetails.bcity = {
      value: apiData?.bCity || bcity.value,
      validator: 'text',
      readonly: bcity.ro,
      required: bcity.rq,
      visible:
        bcity?.visible === null || bcity?.visible === undefined
          ? false
          : bcity?.visible,
      type: 'text',
      label: properties?.bcity || 'City',
    };
  }
  if (oneObject.bstate !== null) {
    const { bstate } = oneObject;
    result.businessDetails.bstate = {
      value:
        apiData?.bState === 'SELECT' || bstate.value.toUpperCase() === 'SELECT'
          ? ''
          : apiData?.bState || bstate.value,
      validator: 'text',
      readonly: bstate.ro,
      required: bstate.rq,
      visible:
        bstate?.visible === null || bstate?.visible === undefined
          ? false
          : bstate?.visible,
      type: 'state',
      label: properties?.bstate || 'State',
    };
  }
  if (oneObject.bzip !== null) {
    const { bzip } = oneObject;
    result.businessDetails.bzip = {
      value: apiData?.bZip || bzip.value,
      validator: 'zipcode',
      readonly: bzip.ro,
      required: bzip.rq,
      visible:
        bzip?.visible === null || bzip?.visible === undefined
          ? false
          : bzip?.visible,
      type: 'zipcode',
      label: properties?.bzip || 'Zipcode',
    };
  }

  let showMaddress = false;
  if (oneObject.maddress !== null) {
    const { maddress } = oneObject;
    if (!showMaddress && maddress?.visible) showMaddress = true;
    result.businessDetails.maddress = {
      value: apiData?.mAddress1 || maddress.value,
      validator: 'text',
      readonly: maddress.ro,
      required: maddress.rq,
      visible:
        maddress?.visible === null || maddress?.visible === undefined
          ? false
          : maddress?.visible,
      type: 'address',
      label: properties?.maddress || 'Mailing Address',
    };
  }
  if (oneObject.maddress1 !== null) {
    const { maddress1 } = oneObject;
    if (!showMaddress && maddress1?.visible) showMaddress = true;
    result.businessDetails.maddress1 = {
      value: apiData?.mAddress2 || maddress1.value,
      validator: 'text',
      readonly: maddress1.ro,
      required: maddress1.rq,
      visible:
        maddress1?.visible === null || maddress1?.visible === undefined
          ? false
          : maddress1?.visible,
      type: 'text',
      label:
        properties?.maddress1 || 'Address 2 (ex: apt, suite, building, etc.)',
    };
  }
  if (oneObject.mcity !== null) {
    const { mcity } = oneObject;
    if (!showMaddress && mcity?.visible) showMaddress = true;
    result.businessDetails.mcity = {
      value: apiData?.mCity || mcity.value,
      validator: 'text',
      readonly: mcity.ro,
      required: mcity.rq,
      visible:
        mcity?.visible === null || mcity?.visible === undefined
          ? false
          : mcity?.visible,
      type: 'text',
      label: properties?.mcity || 'City',
    };
  }
  if (oneObject.mstate !== null) {
    const { mstate } = oneObject;
    if (!showMaddress && mstate?.visible) showMaddress = true;
    result.businessDetails.mstate = {
      value:
        apiData?.mState === 'SELECT' || mstate.value.toUpperCase() === 'SELECT'
          ? ''
          : apiData?.mState || mstate.value,
      validator: 'text',
      readonly: mstate.ro,
      required: mstate.rq,
      visible:
        mstate?.visible === null || mstate?.visible === undefined
          ? false
          : mstate?.visible,
      type: 'state',
      label: properties?.mstate || 'State',
    };
  }
  if (oneObject.mzip !== null) {
    const { mzip } = oneObject;
    if (!showMaddress && mzip?.visible) showMaddress = true;
    result.businessDetails.mzip = {
      value: apiData?.mZip || mzip.value,
      validator: 'zipcode',
      readonly: mzip.ro,
      required: mzip.rq,
      visible:
        mzip?.visible === null || mzip?.visible === undefined
          ? false
          : mzip?.visible,
      type: 'zipcode',
      label: properties?.mzip || 'Zipcode',
    };
  }
  if (oneObject.mcountry !== null) {
    const { mcountry } = oneObject;
    if (!showMaddress && mcountry?.visible) showMaddress = true;
    result.businessDetails.mcountry = {
      value:
        apiData?.mCountry === 'SELECT' ||
        mcountry.value.toUpperCase() === 'SELECT'
          ? ''
          : apiData?.mCountry || mcountry.value,
      validator: 'text',
      readonly: mcountry.ro,
      required: mcountry.rq,
      visible:
        mcountry?.visible === null || mcountry?.visible === undefined
          ? false
          : mcountry?.visible,
      type: 'country',
      label: properties?.mcountry || 'Country',
    };
  }
  result.businessDetails.showMaddress = showMaddress;
  return result;
};
/**
 * It takes a JSON object and returns a new JSON object with the same data, but with a different
 * structure more useful for front-end applications
 * @param {any} OwnerData - any
 * @returns An object with the following properties:
 *   - owners: an object with the following properties:
 *     - ownername: an object with the following properties:
 *       - value: the value of the ownername field
 *       - validator: the validator of the ownername field
 *       - readonly: the readonly value of the ownername field
 *       - required: the required
 */
const middlewareApiOwner = (apiData: any): any => {
  const builderData = apiData.builderData?.owners?.own_list;
  const result: any = {
    owners: [],
  };
  if (builderData === null || builderData === undefined) {
    return result;
  }
  const ownerData = (apiData?.ownerData?.length && apiData?.ownerData) || [
    builderData,
  ];
  const { properties } = apiData;
  if (builderData === null || builderData === undefined) {
    return result;
  }
  ownerData.map((oneObject: any, index: number): any => {
    result.owners[+index] = {};
    if (builderData.ownername !== null) {
      const value =
        oneObject.ownername?.value !== undefined
          ? oneObject.ownername?.value
          : oneObject.ownername;
      result.owners[+index].ownername = {
        value: value || '',
        validator: 'ownername',
        readonly: builderData.ownername.ro,
        required: builderData.ownername.rq,
        visible:
          builderData.ownername?.visible === null ||
          builderData.ownername?.visible === undefined
            ? false
            : builderData.ownername?.visible,
        type: 'ownername',
        label: properties?.ownername || 'Owner Legal Name',
      };
    }
    if (builderData.ownertitle !== null) {
      const value =
        oneObject.ownertitle?.value !== undefined
          ? oneObject.ownertitle?.value
          : oneObject.ownertitle;
      result.owners[+index].ownertitle = {
        value: value || '',
        validator: 'text',
        readonly: builderData.ownertitle.ro,
        required: builderData.ownertitle.rq,
        visible:
          builderData.ownertitle?.visible === null ||
          builderData.ownertitle?.visible === undefined
            ? false
            : builderData.ownertitle?.visible,
        type: 'text',
        label: properties?.ownertitle || 'Owner Title',
        toolTip: 'Business Title / Role of the Owner',
      };
    }
    if (builderData.ownerpercent !== null) {
      const value =
        oneObject.ownerpercent?.value !== undefined
          ? oneObject.ownerpercent?.value
          : oneObject.ownerpercent;
      result.owners[+index].ownerpercent = {
        value: value || 0,
        validator: 'number',
        readonly: builderData.ownerpercent.ro,
        required: builderData.ownerpercent.rq,
        visible:
          builderData.ownerpercent?.visible === null ||
          builderData.ownerpercent?.visible === undefined
            ? false
            : builderData.ownerpercent?.visible,
        type: 'percentage',
        label: properties?.ownerpercent || 'Ownership %',
      };
    }
    if (builderData.ownerssn !== null) {
      const value =
        oneObject.ownerssn?.value !== undefined
          ? oneObject.ownerssn?.value
          : oneObject.ownerssn;
      result.owners[+index].ownerssn = {
        value: value || '',
        validator: 'ssn',
        readonly: builderData.ownerssn.ro,
        required: builderData.ownerssn.rq,
        visible:
          builderData.ownerssn?.visible === null ||
          builderData.ownerssn?.visible === undefined
            ? false
            : builderData.ownerssn?.visible,
        type: 'ssn',
        label: properties?.ownerssn || 'Owner SSN',
        toolTip:
          'Social Security Number of the Owner. Federal Law requires us to request this information',
      };
    }
    if (builderData.ownerdob !== null) {
      const apiDob =
        oneObject.ownerdob?.value !== undefined
          ? oneObject.ownerdob?.value
          : oneObject.ownerdob;
      const todayLess19 = new Date(
        new Date().getFullYear() - 19,
        new Date().getMonth(),
        new Date().getDay()
      );
      const value = new Date(apiDob) > todayLess19 ? todayLess19 : apiDob;
      result.owners[+index].ownerdob = {
        value,
        validator: 'date',
        readonly: builderData.ownerdob.ro,
        required: builderData.ownerdob.rq,
        visible:
          builderData.ownerdob?.visible === null ||
          builderData.ownerdob?.visible === undefined
            ? false
            : builderData.ownerdob?.visible,
        type: 'date',
        label: properties?.ownerdob || 'Date of Birth',
      };
    }
    if (builderData.ownerphone1 !== null) {
      const value =
        oneObject.ownerphone1?.value !== undefined
          ? oneObject.ownerphone1?.value
          : oneObject.ownerphone1;
      result.owners[+index].ownerphone1 = {
        value: value || '',
        validator: 'phone',
        readonly: builderData.ownerphone1.ro,
        required: builderData.ownerphone1.rq,
        visible:
          builderData.ownerphone1?.visible === null ||
          builderData.ownerphone1?.visible === undefined
            ? false
            : builderData.ownerphone1?.visible,
        type: 'phone',
        label: properties?.ownerphone1 || 'Phone Number',
        toolTip: 'Direct Phone Number of the owner',
      };
    }
    if (builderData?.ownerphone2 !== null) {
      const value =
        oneObject.ownerphone2?.value !== undefined
          ? oneObject.ownerphone2?.value
          : oneObject.ownerphone2;
      result.owners[+index].ownerphone2 = {
        value: value || '',
        validator: 'phone',
        readonly: builderData.ownerphone2.ro,
        required: builderData.ownerphone2.rq,
        visible:
          builderData.ownerphone2?.visible === null ||
          builderData.ownerphone2?.visible === undefined
            ? false
            : builderData.ownerphone2?.visible,
        type: 'phone',
        label: properties?.ownerphone2 || 'Additional Phone Number',
      };
    }
    if (builderData.owneremail !== null) {
      const value =
        oneObject.owneremail?.value !== undefined
          ? oneObject.owneremail?.value
          : oneObject.owneremail;
      result.owners[+index].owneremail = {
        value: value || '',
        validator: 'email',
        readonly: builderData.owneremail.ro,
        required: builderData.owneremail.rq,
        visible:
          builderData.owneremail?.visible === null ||
          builderData.owneremail?.visible === undefined
            ? false
            : builderData.owneremail?.visible,
        type: 'email',
        label: properties?.owneremail || 'Email Address',
        toolTip: 'Direct Email of the Owner',
      };
    }
    if (builderData.ownerdriver !== null) {
      const value =
        oneObject.ownerdriver?.value !== undefined
          ? oneObject.ownerdriver?.value
          : oneObject.ownerdriver;
      result.owners[+index].ownerdriver = {
        value: value || '',
        validator: 'alphanumeric',
        readonly: builderData.ownerdriver.ro,
        required: builderData.ownerdriver.rq,
        visible:
          builderData.ownerdriver?.visible === null ||
          builderData.ownerdriver?.visible === undefined
            ? false
            : builderData.ownerdriver?.visible,
        type: 'alphanumeric',
        label: properties?.ownerdriver || "Driver's License #",
      };
    }
    if (builderData.odriverstate !== null) {
      const value =
        oneObject.odriverstate?.value !== undefined
          ? oneObject.odriverstate?.value
          : oneObject.odriverstate;
      result.owners[+index].odriverstate = {
        value: value || '',
        validator: 'text',
        readonly: builderData.odriverstate.ro,
        required: builderData.odriverstate.rq,
        visible:
          builderData.odriverstate?.visible === null ||
          builderData.odriverstate?.visible === undefined
            ? false
            : builderData.odriverstate?.visible,
        type: 'state',
        label: properties?.odriverstate || "Driver's License State",
      };
    }
    if (builderData.oaddress !== null) {
      const value =
        oneObject.oaddress?.value !== undefined
          ? oneObject.oaddress?.value
          : oneObject.oaddress;
      result.owners[+index].oaddress = {
        value: value || '',
        validator: 'text',
        readonly: builderData.oaddress.ro,
        required: builderData.oaddress.rq,
        visible:
          builderData.oaddress?.visible === null ||
          builderData.oaddress?.visible === undefined
            ? false
            : builderData.oaddress?.visible,
        type: 'address',
        label: properties?.oaddress || 'Owner Residential Address',
        toolTip:
          'Residential Street Address of Owner. Federal Law requires us to request this information',
      };
    }
    if (builderData.ocity !== null) {
      const value =
        oneObject.ocity?.value !== undefined
          ? oneObject.ocity?.value
          : oneObject.ocity;
      result.owners[+index].ocity = {
        value: value || '',
        validator: 'text',
        readonly: builderData.ocity.ro,
        required: builderData.ocity.rq,
        visible:
          builderData.ocity?.visible === null ||
          builderData.ocity?.visible === undefined
            ? false
            : builderData.ocity?.visible,
        type: 'text',
        label: properties?.ocity || 'City',
      };
    }
    if (builderData.ostate !== null) {
      const value =
        oneObject.ostate?.value !== undefined
          ? oneObject.ostate?.value
          : oneObject.ostate;
      result.owners[+index].ostate = {
        value: value || '',
        validator: 'text',
        readonly: builderData.ostate.ro,
        required: builderData.ostate.rq,
        visible:
          builderData.ostate?.visible === null ||
          builderData.ostate?.visible === undefined
            ? false
            : builderData.ostate?.visible,
        type: 'state',
        label: properties?.ostate || 'State',
      };
    }
    if (builderData.ozip !== null) {
      const value =
        oneObject.ozip?.value !== undefined
          ? oneObject.ozip?.value
          : oneObject.ozip;
      result.owners[+index].ozip = {
        value: value || '',
        validator: 'zipcode',
        readonly: builderData.ozip.ro,
        required: builderData.ozip.rq,
        visible:
          builderData.ozip?.visible === null ||
          builderData.ozip?.visible === undefined
            ? false
            : builderData.ozip?.visible,
        type: 'zipcode',
        label: properties?.ozip || 'Zipcode',
      };
    }
    if (builderData.ocountry !== null) {
      const value =
        oneObject.ocountry?.value !== undefined
          ? oneObject.ocountry?.value
          : oneObject.ocountry;
      result.owners[+index].ocountry = {
        value: value || '',
        validator: 'text',
        readonly: builderData.ocountry.ro,
        required: builderData.ocountry.rq,
        visible:
          builderData.ocountry?.visible === null ||
          builderData.ocountry?.visible === undefined
            ? false
            : builderData.ocountry?.visible,
        type: 'country',
        label: properties?.ocountry || 'Country',
      };
    }
  });

  return result;
};
/**
 * It takes a JSON object and returns a JSON object
 * @param {any} ContactData - any: This is the data that is passed in from the API.
 * @returns An object with the key 'contacts' and the value of an object with the keys of the contact
 * information.
 */
const middlewareApiContact = (apiData: any): any => {
  const builderData = apiData.builderData?.owners?.contact_list;
  const result: any = {
    contacts: [],
  };
  if (builderData === null || builderData === undefined) {
    return result;
  }
  const ContactData = (apiData?.contactData?.length &&
    apiData?.contactData) || [builderData];
  const { properties } = apiData;
  if (builderData === null) {
    return result;
  }
  ContactData.map((oneObject: any, index: number): any => {
    result.contacts[+index] = {};
    if (oneObject === null) {
      return result;
    }
    if (builderData.contactName !== null) {
      const value =
        oneObject.contactName?.value !== undefined
          ? oneObject.contactName?.value
          : oneObject.contactName;
      result.contacts[+index].contactName = {
        value,
        validator: 'ownername',
        readonly: builderData.contactName.ro,
        required: builderData.contactName.rq,
        visible:
          builderData.contactName?.visible === null ||
          builderData.contactName?.visible === undefined
            ? false
            : builderData.contactName?.visible,
        type: 'ownername',
        label: properties?.contactname || 'Contact Name',
      };
    }
    if (builderData.contactTitle !== null) {
      const value =
        oneObject.contactTitle?.value !== undefined
          ? oneObject.contactTitle?.value
          : oneObject.contactTitle;
      result.contacts[+index].contactTitle = {
        value,
        validator: 'text',
        readonly: builderData.contactTitle.ro,
        required: builderData.contactTitle.rq,
        visible:
          builderData.contactTitle?.visible === null ||
          builderData.contactTitle?.visible === undefined
            ? false
            : builderData.contactTitle?.visible,
        type: 'text',
        label: properties?.contacttitle || 'Contact Title',
      };
    }
    if (builderData.contactPhone !== null) {
      const value =
        oneObject.contactPhone?.value !== undefined
          ? oneObject.contactPhone?.value
          : oneObject.contactPhone;
      result.contacts[+index].contactPhone = {
        value,
        validator: 'phone',
        readonly: builderData.contactPhone.ro,
        required: builderData.contactPhone.rq,
        visible:
          builderData.contactPhone?.visible === null ||
          builderData.contactPhone?.visible === undefined
            ? false
            : builderData.contactPhone?.visible,
        type: 'phone',
        label: properties?.contactphone || 'Contact Phone',
      };
    }
    if (builderData.contactEmail !== null) {
      const value =
        oneObject.contactEmail?.value !== undefined
          ? oneObject.contactEmail?.value
          : oneObject.contactEmail;
      result.contacts[+index].contactEmail = {
        value,
        validator: 'email',
        readonly: builderData.contactEmail.ro,
        required: builderData.contactEmail.rq,
        visible:
          builderData.contactEmail?.visible === null ||
          builderData.contactEmail?.visible === undefined
            ? false
            : builderData.contactEmail?.visible,
        type: 'email',
        label: properties?.contactemail || 'Contact Email',
      };
    }
  });
  return result;
};
/**
 * It takes a JSON object and returns a JSON object
 * @param {any} ProcessingData - The data that is returned from the API.
 * @returns An object with the following properties:
 *   processing: {
 *     mcc: {
 *       value: '',
 *       validator: 'text',
 *       readonly: false,
 *       required: false,
 *       type: 'selectionSearch',
 *       stype: 'mcc',
 *       label: 'Industry (Merchant Category Code)'
 *     },
 */
const middlewareApiProcessing = (apiData: any): any => {
  const ProcessingData: any = apiData.builderData.processing;
  const { properties } = apiData;
  const result: any = {
    processing: {},
    validFields: {},
  };
  if (ProcessingData === null || ProcessingData === undefined) {
    return result;
  }
  if (ProcessingData === null) {
    return result;
  }
  if (ProcessingData.mcc !== null) {
    const { mcc } = ProcessingData;
    result.processing.mcc = {
      value: apiData?.mccid || mcc.value,
      validator: 'text',
      readonly: mcc.ro,
      required: mcc.rq,
      visible:
        mcc?.visible === null || mcc?.visible === undefined
          ? false
          : mcc?.visible,
      type: 'selectionSearch',
      stype: 'mcc',
      label: properties?.mcc || 'Industry (Merchant Category Code)',
    };
  }
  if (ProcessingData.bsummary !== null) {
    const { bsummary } = ProcessingData;
    result.processing.bsummary = {
      value: apiData?.bSummary || bsummary.value,
      validator: 'text',
      readonly: bsummary.ro,
      required: bsummary.rq,
      visible:
        bsummary?.visible === null || bsummary?.visible === undefined
          ? false
          : bsummary?.visible,
      type: 'text',
      label: properties?.bsummary || 'Tell us about your Business',
      toolTip: 'Please provide some background on your Business',
    };
  }
  if (ProcessingData.binperson !== null) {
    const { binperson } = ProcessingData;
    result.processing.binperson = {
      value: apiData?.binPerson || binperson.value,
      validator: 'float',
      readonly: binperson.ro,
      required: binperson.rq,
      visible:
        binperson?.visible === null || binperson?.visible === undefined
          ? false
          : binperson?.visible,
      type: 'percentage',
      label: properties?.binperson || 'Percent of payments In Person',
    };
  }
  if (ProcessingData.binphone !== null) {
    const { binphone } = ProcessingData;
    result.processing.binphone = {
      value: apiData?.binPhone || binphone.value,
      validator: 'float',
      readonly: binphone.ro,
      required: binphone.rq,
      visible:
        binphone?.visible === null || binphone?.visible === undefined
          ? false
          : binphone?.visible,
      type: 'percentage',
      label: 'Percent of payments by Phone',
    };
  }
  if (ProcessingData.binweb !== null) {
    const { binweb } = ProcessingData;
    result.processing.binweb = {
      value: apiData?.binWeb || binweb.value,
      validator: 'float',
      readonly: binweb.ro,
      required: binweb.rq,
      visible:
        binweb?.visible === null || binweb?.visible === undefined
          ? false
          : binweb?.visible,
      type: 'percentage',
      label: properties?.binweb || 'Percent of payments online',
    };
  }
  if (
    ProcessingData.binperson === null &&
    ProcessingData.binphone === null &&
    ProcessingData.binweb === null
  ) {
    result.validFields.validPercentOfPayments = false;
  } else {
    result.validFields.validPercentOfPayments = true;
  }
  if (ProcessingData.avgmonthly !== null) {
    const { avgmonthly } = ProcessingData;
    result.processing.avgmonthly = {
      value: apiData?.averageMonthlyVolume || avgmonthly.value,
      validator: 'float',
      readonly: avgmonthly.ro,
      required: avgmonthly.rq,
      visible:
        avgmonthly?.visible === null || avgmonthly?.visible === undefined
          ? false
          : avgmonthly?.visible,
      type: 'money',
      label: properties?.avgmonthly || 'Average Monthly Volume',
      toolTip:
        'What is the average total card and ACH volume you anticipate per month?',
    };
  }
  if (ProcessingData.ticketamt !== null) {
    const { ticketamt } = ProcessingData;
    result.processing.ticketamt = {
      value: apiData?.averageTicketAmount || ticketamt.value,
      validator: 'float',
      readonly: ticketamt.ro,
      required: ticketamt.rq,
      visible:
        ticketamt?.visible === null || ticketamt?.visible === undefined
          ? false
          : ticketamt?.visible,
      type: 'money',
      label: properties?.ticketamt || 'Average Transaction Amount',
      toolTip:
        'What is the average transaction value that you charge your customers by card or ACH?',
    };
  }
  if (ProcessingData.highticketamt !== null) {
    const { highticketamt } = ProcessingData;
    result.processing.highticketamt = {
      value: apiData?.highTicketAmount || highticketamt.value,
      validator: 'float',
      readonly: highticketamt.ro,
      required: highticketamt.rq,
      visible:
        highticketamt?.visible === null || highticketamt?.visible === undefined
          ? false
          : highticketamt?.visible,
      type: 'money',
      label: properties?.highticketamt || 'High Transaction Amount',
      toolTip:
        'What is the highest transaction value on a single charge you will charge your customers by card or ACH?',
    };
  }
  if (ProcessingData.annualRevenue !== null) {
    const { annualRevenue } = ProcessingData;
    result.processing.annualRevenue = {
      value: apiData?.annualRevenue || annualRevenue.value,
      validator: 'float',
      readonly: annualRevenue.ro,
      required: annualRevenue.rq,
      visible:
        annualRevenue?.visible === null || annualRevenue?.visible === undefined
          ? false
          : annualRevenue?.visible,
      type: 'money',
      label: properties?.annualRevenue || 'Annual Revenue Volume',
      toolTip: 'What is the Annual Revenue Volume?',
    };
  }
  if (ProcessingData.whenCharged !== null) {
    const { whenCharged } = ProcessingData;
    result.processing.whenCharged = {
      value: apiData?.whencharged || whenCharged.value,
      validator: 'text',
      readonly: whenCharged.ro,
      required: whenCharged.rq,
      visible:
        whenCharged?.visible === null || whenCharged?.visible === undefined
          ? false
          : whenCharged?.visible,
      type: 'selection',
      stype: 'whencharged',
      label: properties?.whenCharged || 'When is Payment Charged',
    };
  }
  if (ProcessingData.whenProvided !== null) {
    const { whenProvided } = ProcessingData;
    result.processing.whenProvided = {
      value: apiData?.whenprovided || whenProvided.value,
      validator: 'text',
      readonly: whenProvided.ro,
      required: whenProvided.rq,
      visible:
        whenProvided?.visible === null || whenProvided?.visible === undefined
          ? false
          : whenProvided?.visible,
      type: 'selection',
      stype: 'whenprovided',
      label: properties?.whenProvided || 'Service Generally Provided In',
    };
  }
  if (ProcessingData.whenDelivered !== null) {
    const { whenDelivered } = ProcessingData;
    result.processing.whenDelivered = {
      value: apiData?.whendelivered || whenDelivered.value,
      validator: 'text',
      readonly: whenDelivered.ro,
      required: whenDelivered.rq,
      visible:
        whenDelivered?.visible === null || whenDelivered?.visible === undefined
          ? false
          : whenDelivered?.visible,
      type: 'selection',
      stype: 'whendelivered',
      label: properties?.whenDelivered || 'Products/Services Delivered In',
    };
  }
  if (ProcessingData.whenRefunded !== null) {
    const { whenRefunded } = ProcessingData;
    result.processing.whenRefunded = {
      value: apiData?.whenrefund || whenRefunded.value,
      validator: 'text',
      readonly: whenRefunded.ro,
      required: whenRefunded.rq,
      visible:
        whenRefunded?.visible === null || whenRefunded?.visible === undefined
          ? false
          : whenRefunded?.visible,
      type: 'selection',
      stype: 'whenrefunded',
      label: properties?.whenRefunded || 'Refund Policy',
    };
  }
  return result;
};
/**
 * It takes Banking Data JSON object and returns a JSON object more useful for formatting.
 * @param {any} BannkingData - The data that you want to convert to the middleware format.
 */
const middlewareApiBanking = (apiData: any): any => {
  const builderData = apiData.builderData?.banking;
  const bankingData = apiData?.bankData || [builderData];
  const { properties } = apiData;
  const result: any = {
    banking: {},
  };
  if (builderData === null) return result;
  if (bankingData === null) return result;
  // depositAccount
  if (builderData?.depositAccount !== null) {
    result.banking.depositAccount = {};
    const depositAccount = bankingData?.length
      ? bankingData[0]
      : bankingData.depositAccount;
    const bankName = depositAccount.bankName
      ? depositAccount.bankName
      : builderData.depositAccount.bankName;
    if (builderData.depositAccount.bankName !== null) {
      const value = bankName?.value !== undefined ? bankName?.value : bankName;
      result.banking.depositAccount.bankName = {
        value,
        validator: 'text',
        visible:
          builderData.depositAccount.bankName?.visible === null ||
          builderData.depositAccount.bankName?.visible === undefined
            ? false
            : builderData.depositAccount.bankName?.visible,
        readonly: builderData.depositAccount.bankName.ro,
        required: builderData.depositAccount.bankName.rq,
        type: 'text',
        label: properties?.depositBankName || 'Bank Name',
        toolTip: "Bank Account you'd like us to send funds to",
      };
    }
    const accountNumber = depositAccount.accountNumber
      ? depositAccount.accountNumber
      : builderData.depositAccount.accountNumber;
    if (builderData.depositAccount.accountNumber !== null) {
      const value =
        accountNumber?.value !== undefined
          ? accountNumber?.value
          : accountNumber;
      result.banking.depositAccount.accountNumber = {
        value,
        validator: 'accountNumber',
        visible:
          builderData.depositAccount.accountNumber?.visible === null ||
          builderData.depositAccount.accountNumber?.visible === undefined
            ? false
            : builderData.depositAccount.accountNumber?.visible,
        readonly: builderData.depositAccount.accountNumber.ro,
        required: builderData.depositAccount.accountNumber.rq,
        type: 'accountNumber',
        label: properties?.depositAccountNumber || 'Account Number',
      };
    }

    const routingAccount = depositAccount.routingAccount
      ? depositAccount.routingAccount
      : builderData.depositAccount.routingAccount;
    if (builderData.depositAccount.routingAccount !== null) {
      const value =
        routingAccount?.value !== undefined
          ? routingAccount?.value
          : routingAccount;
      result.banking.depositAccount.routingAccount = {
        value,
        validator: 'routingNumber',
        visible:
          builderData.depositAccount.routingAccount?.visible === null ||
          builderData.depositAccount.routingAccount?.visible === undefined
            ? false
            : builderData.depositAccount.routingAccount?.visible,
        readonly: builderData.depositAccount.routingAccount.ro,
        required: builderData.depositAccount.routingAccount.rq,
        type: 'routing',
        label: properties?.depositRoutingAccount || 'Routing Number',
      };
    }
    const typeAccount = depositAccount.typeAccount
      ? depositAccount.typeAccount
      : builderData.depositAccount.typeAccount;
    if (builderData.depositAccount.typeAccount !== null) {
      const value =
        typeAccount?.value !== undefined ? typeAccount?.value : typeAccount;
      result.banking.depositAccount.typeAccount = {
        value,
        validator: 'text',
        visible:
          builderData.depositAccount.typeAccount?.visible === null ||
          builderData.depositAccount.typeAccount?.visible === undefined
            ? false
            : builderData.depositAccount.typeAccount?.visible,
        readonly: builderData.depositAccount.typeAccount.ro,
        required: builderData.depositAccount.typeAccount.rq,
        type: 'selection',
        stype: 'bank-type',
        label: properties?.depositTypeAccount || 'Account Type',
      };
    }
  }
  // withdrawalAccount
  if (builderData?.withdrawalAccount !== null) {
    result.banking.withdrawalAccount = {};
    const withdrawalAccount =
      bankingData?.length > 1
        ? bankingData[1]
        : bankingData?.length === 1
        ? bankingData[0]
        : bankingData.withdrawalAccount;

    const bankName = withdrawalAccount.bankName
      ? withdrawalAccount.bankName
      : builderData.withdrawalAccount.bankName;
    if (builderData.withdrawalAccount.bankName !== null) {
      const value = bankName?.value !== undefined ? bankName?.value : bankName;
      result.banking.withdrawalAccount.bankName = {
        value,
        validator: 'text',
        visible:
          builderData.withdrawalAccount.bankName?.visible === null ||
          builderData.withdrawalAccount.bankName?.visible === undefined
            ? false
            : builderData.withdrawalAccount.bankName?.visible,
        readonly: builderData.withdrawalAccount.bankName.ro,
        required: builderData.withdrawalAccount.bankName.rq,
        type: 'text',
        label: properties?.depositTypeAccount || 'Bank Name',
        toolTip: "Bank Account you'd like us to withdraw funds and fees from",
      };
    }
    const accountNumber = withdrawalAccount.accountNumber
      ? withdrawalAccount.accountNumber
      : builderData.withdrawalAccount.accountNumber;
    if (builderData.withdrawalAccount.accountNumber !== null) {
      const value =
        accountNumber?.value !== undefined
          ? accountNumber?.value
          : accountNumber;
      result.banking.withdrawalAccount.accountNumber = {
        value,
        validator: 'accountNumber',
        visible:
          builderData.withdrawalAccount.accountNumber?.visible === null ||
          builderData.withdrawalAccount.accountNumber?.visible === undefined
            ? false
            : builderData.withdrawalAccount.accountNumber?.visible,
        readonly: builderData.withdrawalAccount.accountNumber.ro,
        required: builderData.withdrawalAccount.accountNumber.rq,
        type: 'accountNumber',
        label: properties?.withdrawalAccountNumber || 'Account Number',
      };
    }
    const routingAccount = withdrawalAccount.routingAccount
      ? withdrawalAccount.routingAccount
      : builderData.withdrawalAccount.routingAccount;
    if (builderData.withdrawalAccount.routingAccount !== null) {
      const value =
        routingAccount?.value !== undefined
          ? routingAccount?.value
          : routingAccount;
      result.banking.withdrawalAccount.routingAccount = {
        value,
        validator: 'routingNumber',
        visible:
          builderData.withdrawalAccount.routingAccount?.visible === null ||
          builderData.withdrawalAccount.routingAccount?.visible === undefined
            ? false
            : builderData.withdrawalAccount.routingAccount?.visible,
        readonly: builderData.withdrawalAccount.routingAccount.ro,
        required: builderData.withdrawalAccount.routingAccount.rq,
        type: 'routing',
        label: properties?.withdrawalRoutingAccount || 'Routing Number',
      };
    }
    const typeAccount = withdrawalAccount.typeAccount
      ? withdrawalAccount.typeAccount
      : builderData.withdrawalAccount.typeAccount;
    if (builderData.withdrawalAccount.typeAccount !== null) {
      const value =
        typeAccount?.value !== undefined ? typeAccount?.value : typeAccount;
      result.banking.withdrawalAccount.typeAccount = {
        value,
        validator: 'text',
        visible:
          builderData.withdrawalAccount.typeAccount?.visible === null ||
          builderData.withdrawalAccount.typeAccount?.visible === undefined
            ? false
            : builderData.withdrawalAccount.typeAccount?.visible,
        readonly: builderData.withdrawalAccount.typeAccount.ro,
        required: builderData.withdrawalAccount.typeAccount.rq,
        type: 'selection',
        stype: 'bank-type',
        label: properties?.withdrawalTypeAccount || 'Account Type',
      };
    }
  }
  return result;
};
/**
 * It takes in an object, and returns an object
 * @param {any} servicesCardData - This is the data that is returned from the API.
 * @returns The servicesCardData is being returned.
 */
const middlewareApiServicesCard = (servicesCardData: any): any => {
  const result: any = {
    card: {},
  };
  result.card = servicesCardData;

  return servicesCardData;
};
/**
 * It takes in an object, and returns an object
 * @param {any} servicesAchData - This is the data that is returned from the API call.
 * @returns The middlewareApiServicesAch function is returning the servicesAchData object.
 */
const middlewareApiServicesAch = (servicesAchData: any): any => {
  const result: any = {
    ach: {},
  };
  result.ach = servicesAchData;

  return servicesAchData;
};
const middlewareApiServicesMgm = (servicesMgmData: any): any => {
  const result: any = {
    mgm: {},
  };
  result.mgm = servicesMgmData;

  return servicesMgmData;
};
/**
 * It takes a servicesData object and returns a new object with the same data, but with a different
 * structure
 * @param {any} servicesData - The data that is returned from the API.
 */
const middlewareApiServices = (servicesData: any): any => {
  const result: any = {
    services: {},
  };
  if (servicesData === null) return result;
  if (servicesData.card !== null) {
    const { card } = servicesData;
    const cardServices = middlewareApiServicesCard(card);
    result.services.card = cardServices;
  }
  if (servicesData.ach !== null) {
    const { ach } = servicesData;
    const achServices = middlewareApiServicesAch(ach);
    result.services.ach = achServices;
  }
  if (servicesData.mgm !== null) {
    const { mgm } = servicesData;
    const mgmServices = middlewareApiServicesMgm(mgm);
    result.services.mgm = mgmServices;
  }
  return result;
};
/**
 * It takes in a termsCoditionData object and returns an object with a termsConditions property
 * @param {any} termsCoditionData - This is the data that you want to pass to the component.
 * @returns An object with a key of termsConditions and a value of termsCoditionData
 */
const middlewareApiTermsConditions = (termsCoditionData: any): any => {
  return {
    termsConditions: termsCoditionData,
  };
};
/**
 * It returns an object with a signer property that has a bunch of properties.
 * @returns An object with a property called signer. The signer property is an object with properties
 * for name, ssn, dob, phone, email, address, address1, city, state, zip, and country. Each of these
 * properties is an object with properties for value, validator, readonly, required, type, and label.
 */
const middlewareApiSigner = (apiData: any): any => {
  const result: any = {
    signer: {},
  };
  const signerData = apiData.signer;
  const { properties } = apiData;
  result.signer.name = {
    value: signerData?.name || '',
    validator: 'text',
    readonly: false,
    required: true,
    type: 'text',
    label: properties?.signername || 'Name',
  };
  result.signer.ssn = {
    value: signerData?.ssn || '',
    validator: 'ssn',
    readonly: false,
    required: true,
    type: 'ssn',
    label: properties?.signername || 'Social Security Number',
  };
  result.signer.dob = {
    value: signerData?.dob,
    validator: 'date',
    readonly: false,
    required: true,
    type: 'date',
    label: properties?.signerssn || 'Date of Birth',
  };
  result.signer.phone = {
    value: signerData?.phone || '',
    validator: 'phone',
    readonly: false,
    required: true,
    type: 'phone',
    label: properties?.signerphone1 || 'Phone',
  };
  result.signer.email = {
    value: signerData?.email || '',
    validator: 'email',
    readonly: false,
    required: true,
    type: 'email',
    label: properties?.signeremail || 'Email',
  };
  result.signer.address = {
    value: signerData?.address || '',
    validator: 'text',
    readonly: false,
    required: true,
    type: 'address',
    label: properties?.signeraddress || 'Residence Address',
  };
  result.signer.address1 = {
    value: '',
    validator: 'text',
    readonly: false,
    required: false,
    type: 'text',
    label:
      properties?.signeraddress2 || 'Address 2 (ex: apt, suite, building, etc)',
  };
  result.signer.city = {
    value: signerData?.city || '',
    validator: 'text',
    readonly: false,
    required: true,
    type: 'text',
    label: properties?.signercity || 'City',
  };
  result.signer.state = {
    value: signerData?.state || '',
    validator: 'text',
    readonly: false,
    required: true,
    type: 'state',
    label: properties?.signerstate || 'State',
  };
  result.signer.zip = {
    value: signerData?.zip || '',
    validator: 'zipcode',
    readonly: false,
    required: true,
    type: 'zipcode',
    label: properties?.signerzip || 'Zipcode',
  };
  result.signer.country = {
    value: signerData?.country || '',
    validator: 'text',
    readonly: false,
    required: true,
    type: 'country',
    label: properties?.signercountry || 'Country',
  };
  return result;
};
/**
 * It takes an object with three properties, and returns an object with the same three properties
 * @param {any} attributes - any - this is the object that is passed to the middleware function.
 * @returns The middlewareApiAttributes function is returning an object with the following properties:
 * multipleOwners, multipleContacts, minimumDocuments
 */
const middlewareApiAttributes = (attributes: any): any => {
  const result: any = {
    multipleOwners: attributes?.multipleOwners,
    multipleContacts: attributes?.multipleContacts,
    minimumDocuments: attributes?.minimumDocuments,
  };
  return result;
};
const middlewareApiDocuments = (apiData: any): any => {
  const documentsRef = apiData?.documentsRef;
  const result: any = {};
  if (documentsRef) {
    result.attachments = documentsRef;
  }

  return result;
};
/**
 * It takes an object with a bunch of nested objects and returns a new object with the same keys but
 * with the nested objects flattened
 * @param {any} apiData - This is the data that is returned from the API.
 * @returns An object with the following properties:
 *   business: Object
 *   owners: Object
 *   contacts: Object
 *   processing: Object
 *   banking: Object
 *   services: Object
 *   termsConditions: Object
 *   signer: Object
 */
export const middlewareApi = (apiData: any): any => {
  const builderData = { builderData: apiData?.builderData };
  const result: Object = {
    ...middlewareApiBusiness(apiData),
    ...middlewareApiOwner(apiData),
    ...middlewareApiContact(apiData),
    ...middlewareApiProcessing(apiData),
    ...middlewareApiBanking(apiData),
    ...middlewareApiServices(apiData.builderData.services),
    ...middlewareApiTermsConditions(
      apiData.builderData.banking?.termsConditions
    ),
    ...middlewareApiSigner(apiData),
    ...middlewareApiDocuments(apiData),
    ...middlewareApiAttributes(apiData.builderData.attributes),
    ...builderData,
  };
  return result;
};
