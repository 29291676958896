import React from 'react';

import CalendarInput from '../components/inputs/CalendarInput';
import CountrySelectInput from '../components/inputs/CountrySelectInput';
import LocationSearchInput from '../components/inputs/LocationSearchInput';
import MaskedInput from '../components/inputs/MaskedInput';
import MoneyInput from '../components/inputs/MoneyInput';
import PercentInput from '../components/inputs/PercentInput';
import PhoneInput from '../components/inputs/PhoneInput';
import RoutingInput from '../components/inputs/RoutingInput';
import SelectionInput from '../components/inputs/SelectionInput';
import SelectionSearchInput from '../components/inputs/SelectionSearchInput';
import StateSelectInput from '../components/inputs/StateSelectInput';
import TextInput from '../components/inputs/TextInput';
import ToolsService from '../services/ToolsService';
import AccountNumberInput from '../components/inputs/AccountNumberInput';

/* The above code is a set of validators that can be used to validate a form. */
export const validators = {
  isEmpty(value: any): boolean {
    if (value === '' || value === null || value === undefined) {
      return true;
    }
    return false;
  },
  /**
   * Validates a routing number by making a request to the API.
   *
   * @param {string} value - The routing number to be validated.
   * @return {Promise<any>} - A promise that resolves to the validation response.
   */
  validateRouting: async (
    value: string,
    accountNumber: string
  ): Promise<any> => {
    const url = `${process.env.REACT_APP_URL_API}Management/verifyAccount`;
    const token = process.env.REACT_APP_TOKEN;
    const myHeaders = new Headers();
    myHeaders.append('requestToken', token || '');
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      routingNumber: value,
      accountNumber,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    const response = await fetch(url, requestOptions);
    return response.text();
  },
  validatePhone: async (value: string): Promise<any> => {
    const token = process.env.REACT_APP_TOKEN;
    const toolService = new ToolsService();
    let validation = {};
    await toolService
      .validatePhone(token, value)
      .then((data) => {
        validation = data;
      })
      .catch((error: any) => {
        validation = error.response;
      });
    return validation;
  },
  stringValidator: (validation: string, value: any): any => {
    if (validation === 'moneynonzero') {
      return (!Number(value) === value && value % 1 !== 0) || value <= 0;
    }
    if (!value) {
      return false;
    }
    switch (validation) {
      case 'alpha':
        return !/^[a-zA-Z\s]*$/.test(value);
      case 'numbers':
        return !/^[0-9]*$/.test(value);
      case 'accountNumber':
        return !/^\d{5,12}$/.test(value);
      case 'accountNumberCA':
        return !/^\d{7,12}$/.test(value);
      case 'float':
        return !Number(value) === value && value % 1 !== 0;
      case 'moneynonzero':
        return (!Number(value) === value && value % 1 !== 0) || value <= 0;
      case 'alphanumeric':
        return !/^([a-zA-Z0-9 _-]+)$/.test(value);
      case 'text':
        return false;
      case 'ownername':
        return !/^(?=.*\b \b)(?=.*\b \b).*$/.test(value);
      case 'email':
        return !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value.toLowerCase()
        );
      case 'emailSemicolon': {
        value = value.replaceAll(' ', '');
        let response = true;
        value.split(';').forEach((item: any) => {
          if (
            !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              item.toLowerCase()
            )
          ) {
            response = false;
          }
        });
        return response;
      }
      case 'phone': {
        value = value
          ?.replace(' ', '')
          ?.replace('(', '')
          ?.replace(')', '')
          ?.replace('-', '');
        return !/^([0-9]{10,10})$/.test(value);
      }
      case 'card':
        return !/^([0-9]{15,16})$/.test(value);
      case 'routing':
        return !/^([0-9]{9,9})$/.test(value);
      case 'routingCA':
        return !/^\d{5}\d{3}$/.test(value);
      case 'exp':
        return !/^([0-9]{4,4})$/.test(value);
      case 'cvvamex':
        return !/^([0-9]{4,4})$/.test(value);
      case 'cvv':
        return !/^([0-9]{3,3})$/.test(value);
      case 'zipcode':
        return !/^(?=.{0,6}$)([A-Za-z0-9])+$/g.test(value);
      case 'zipcodeCA':
        return !/(^\d{5}(-\d{4})?$)|(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)/.test(
          value
        );
      case 'ein': {
        value = value.replace(' ', '');
        return !/^([0-9]{9,9})$/.test(value);
      }
      case 'ssn':
        return !/^([0-9]{9,9})$/.test(value);
      case 'url':
        return !/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi.test(
          value
        );
      case 'urlprotocol':
        return !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi.test(
          value
        );
      default:
        return String;
    }
  },
};

export const capitalizeFirstLetterOfEachWord = (value: string): string => {
  if (typeof value === 'string') {
    return value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  }
  return '';
};

/* A function that takes in a type and fieldMetadata and returns a correct input component from type. */
export const renderInput = (
  type: string,
  fieldMetadata: Record<string, any | string | Object | Array<string>>
): React.ReactNode => {
  const { index } = fieldMetadata;
  const { name } = fieldMetadata;
  const { storeName } = fieldMetadata;
  const { label } = fieldMetadata;
  const { required } = fieldMetadata;
  const { readonly } = fieldMetadata;
  const { toolTip } = fieldMetadata;
  const { boarding } = fieldMetadata;
  const { component } = fieldMetadata;
  const { metadata } = fieldMetadata;
  const { restrictedDob } = fieldMetadata;
  const value =
    boarding[component].getValue(storeName, metadata) || fieldMetadata.value;
  const { identifyField } = fieldMetadata;
  if (type === 'address') {
    const { locationFields } = fieldMetadata;
    return (
      <LocationSearchInput
        key={+index}
        iTitle={label}
        iComponent={component}
        iName={name}
        iRequired={required}
        iReadonly={readonly}
        iStoreName={storeName}
        identifyBy={identifyField}
        iMetadata={metadata}
        iValue={value}
        locationFields={locationFields}
      />
    );
  }
  if (type === 'money') {
    return (
      <MoneyInput
        key={+index}
        iComponent={component}
        iName={name}
        iStoreName={storeName}
        iTitle={label}
        iRequired={required}
        iValue={value}
        iToolTip={toolTip}
        iMetadata={metadata}
        iReadonly={readonly}
        maxValue={0}
      />
    );
  }
  if (type === 'percentage') {
    return (
      <PercentInput
        key={+index}
        iComponent={component}
        iName={name}
        iStoreName={storeName}
        iTitle={label}
        iRequired={required}
        iValue={value}
        iToolTip={toolTip}
        iMetadata={metadata}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'country') {
    return (
      <CountrySelectInput
        key={+index}
        iComponent={component}
        iName={name}
        iStoreName={storeName}
        iTitle={label}
        iRequired={required}
        iValue={value}
        iToolTip={toolTip}
        iMetadata={metadata}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'state') {
    return (
      <StateSelectInput
        key={+index}
        iComponent={component}
        iName={name}
        iStoreName={storeName}
        iTitle={label}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iToolTip={toolTip}
        iMetadata={metadata}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'date') {
    return (
      <CalendarInput
        key={+index}
        iComponent={component}
        iName={name}
        iStoreName={storeName}
        iTitle={label}
        iMax={restrictedDob}
        iRequired={required}
        iToolTip={toolTip}
        iRestrictedDob={restrictedDob}
        identifyBy={identifyField}
        iValue={value || ''}
        iMetadata={metadata}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'selectionSearch') {
    return (
      <SelectionSearchInput
        key={+index}
        iComponent={component}
        iName={name}
        iTitle={label}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iStoreName={storeName}
        iToolTip={toolTip}
        iMetadata={metadata}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'selection') {
    return (
      <SelectionInput
        key={+index}
        iComponent={component}
        iName={name}
        iTitle={label}
        iStoreName={storeName}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iToolTip={toolTip}
        iMetadata={metadata}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'routing') {
    return (
      <RoutingInput
        key={+index}
        iComponent={component}
        iName={name}
        iMask={type}
        iTitle={label}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iStoreName={storeName}
        iType={'text'}
        iMetadata={metadata}
        iToolTip={toolTip}
        iValidator={type}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'phone') {
    return (
      <PhoneInput
        key={+index}
        iComponent={component}
        iName={name}
        iMask={type}
        iTitle={label}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iStoreName={storeName}
        iType={'text'}
        iMetadata={metadata}
        iToolTip={toolTip}
        iValidator={type}
        iReadonly={readonly}
      />
    );
  }
  if (type === 'accountNumber') {
    return (
      <AccountNumberInput
        key={+index}
        iComponent={component}
        iName={name}
        iMask={type}
        iTitle={label}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iStoreName={storeName}
        iType={'text'}
        iMetadata={metadata}
        iToolTip={toolTip}
        iValidator={type}
        iReadonly={readonly}
      />
    );
  }
  if (
    type === 'email' ||
    type === 'ein' ||
    type === 'zipcode' ||
    type === 'ssn' ||
    type === 'url' ||
    type === 'ownername'
  ) {
    return (
      <MaskedInput
        key={+index}
        iComponent={component}
        iName={name}
        iMask={type}
        iTitle={label}
        iRequired={required}
        identifyBy={identifyField}
        iValue={value}
        iStoreName={storeName}
        iType={type === 'ssn' ? 'password' : 'text'}
        iMetadata={metadata}
        iToolTip={toolTip}
        iValidator={type}
        iReadonly={readonly}
      />
    );
  }
  return (
    <TextInput
      key={+index}
      iComponent={component}
      iName={name}
      iValidator={type}
      iStoreName={storeName}
      iTitle={label}
      iRequired={required}
      iValue={value}
      iMetadata={metadata}
      iToolTip={toolTip}
      iReadonly={readonly}
    />
  );
};

/**
 * It returns an array of objects that contain a key, text, and value property to selection type in documents
 * @returns An array of objects
 */
export const getAttachmentsType = (): Array<any> => {
  const type = [
    {
      key: 'personal_id',
      text: 'Personal ID',
      value: 'personal_id',
    },
    {
      key: 'company_id',
      text: 'Company ID',
      value: 'company_id',
    },
    {
      key: 'voided_check',
      text: 'Voided Check',
      value: 'voided_check',
    },
    {
      key: 'bank_statement',
      text: 'Bank Statement',
      value: 'bank_statement',
    },
    {
      key: 'bank_letter',
      text: 'Bank Letter',
      value: 'bank_letter',
    },
  ];
  return type;
};
