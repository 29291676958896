import 'react-datepicker/dist/react-datepicker.css';

import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { getMonths, stringDateFormat } from '../../utils/datetime-tools';
import { validators } from '../../utils/input-tools';

interface CalendarInputProps {
  iValue?: string;
  iComponent: string;
  iStoreName: string;
  iMetadata?: any;
  iRequired?: boolean;
  iReadonly?: boolean;
  iTitle: string;
  iName: string;
  iMax?: Date;
  iToolTip?: string;
  iRestrictedDob?: boolean;
  identifyBy?: string;
  boarding?: any;
}

const CalendarInput: React.FC<CalendarInputProps> = inject('boarding')(
  observer((props) => {
    const [value, setValue] = useState<Date | null>(
      props.iValue ? new Date(props.iValue) : null
    );
    const [isNewScreen, setIsNewScreen] = useState<boolean>(true);
    const isUserInput = useRef(false);

    useEffect(() => {
      if (!isUserInput.current) {
        const newValue = props.iValue ? new Date(props.iValue) : null;
        const validationError =
          props.iRequired && validators.isEmpty(props.iValue);
        if (
          stringDateFormat(newValue?.toISOString() as string) !==
            stringDateFormat(value?.toISOString() as string) ||
          validationError
        ) {
          setValue(newValue);
          props.boarding[props.iComponent].setProp(
            props.iStoreName,
            newValue,
            props.iMetadata
          );
          props.boarding[props.iComponent].setError(
            props.iStoreName,
            validationError,
            props.iMetadata
          );
        }
      }
      isUserInput.current = false;
    }, [props.iValue]);

    const handleChange = (name: string, pvalue: Date | null): void => {
      isUserInput.current = true;
      const validationError = props.iRequired && validators.isEmpty(pvalue);
      setValue(pvalue);
      setIsNewScreen(false);
      props.boarding[props.iComponent].setProp(name, pvalue, props.iMetadata);
      props.boarding[props.iComponent].setError(
        name,
        validationError,
        props.iMetadata
      );
    };

    const isCorrectDate = (date: Date | null): boolean => {
      if (date instanceof Date) {
        const text = Date.prototype.toString.call(date);
        return text !== 'Invalid Date';
      }
      return false;
    };

    const error =
      props.boarding[props.iComponent].getError(
        props.iStoreName,
        props.iMetadata
      ) === true;
    const { nextClicked } = props.boarding;
    const years: number[] = [];
    const months: string[] = getMonths();
    const year = new Date().getFullYear();
    for (let i = 1900; i <= year; i++) {
      years.push(i);
    }

    return (
      <div className="">
        <DatePicker
          customInput={
            <div
              className={
                (error && !isNewScreen) || (error && nextClicked)
                  ? 'error ui fluid input field field--not-empty'
                  : 'ui fluid input field field--not-empty'
              }
            >
              <label className="field__label" htmlFor={props.iName}>
                {props.iTitle} {props.iRequired ? ' *' : ''}
              </label>
              <input
                disabled={props.iReadonly}
                className="field__input input-calendar"
                value={
                  value && isCorrectDate(value)
                    ? stringDateFormat(value.toISOString())
                    : ''
                }
                name={props.iStoreName}
                placeholder={props.iTitle}
              />
            </div>
          }
          selected={isCorrectDate(value) ? value : null}
          onChange={(valueChange): void =>
            handleChange(props.iStoreName, valueChange)
          }
          maxDate={props.iMax}
          readOnly={props.iReadonly}
          disabled={props.iReadonly}
          dayClassName={(): string => 'calendar-day'}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }): any => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </button>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }): void => changeYear(+value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }): void =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </button>
            </div>
          )}
        />
      </div>
    );
  })
);

export default inject('boarding')(observer(CalendarInput));
