import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Card,
  Container,
  Grid,
  Icon,
  Image,
  Table,
  Transition,
} from 'semantic-ui-react';

import achIcon from '../assets/images/ach.svg';
import amex from '../assets/images/amex.png';
import discover from '../assets/images/discover.svg';
import mastercard from '../assets/images/mastercard.svg';
import mgmIcon from '../assets/images/mgm.svg';
import visa from '../assets/images/visa.svg';
import { capitalizeFirstLetterOfEachWord } from '../utils/input-tools';

class Pricing extends React.Component<any, any> {
  serviceData: any;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.serviceData = props.serviceData;
  }

  componentDidMount(): void {
    this.setState({
      visible: true,
    });
  }

  getTabPricing(
    cards: any,
    acceptance: any,
    notVisibleColumns: any,
    serviceType?: string
  ): any {
    const headers = cards.header.columns;
    const visiblesHeaders: any = {};
    headers.map((header: any, index: number) => {
      if (!notVisibleColumns.includes(index)) {
        header.type = header.value.includes('oneTime')
          ? 'oneTime'
          : header.value.includes('recurring')
          ? 'recurring'
          : !header.value.includes('recurring') &&
            !header.value.includes('oneTime') &&
            header.value.includes('Auth')
          ? 'flat'
          : header.value.includes('pay range')
          ? 'range'
          : 'card';
        header.operation = header.value.includes('%')
          ? 'percentage'
          : header.value.includes('$')
          ? 'money'
          : header.value.includes('low')
          ? 'low'
          : header.value.includes('high')
          ? 'high'
          : 'card';
        if (header.type === 'oneTime' && header.operation === 'money') {
          visiblesHeaders.onetime_money = index;
        } else if (
          header.type === 'oneTime' &&
          header.operation === 'percentage'
        ) {
          visiblesHeaders.onetime_percenntage = index;
        } else if (
          header.type === 'recurring' &&
          header.operation === 'money'
        ) {
          visiblesHeaders.recurring_money = index;
        } else if (
          header.type === 'recurring' &&
          header.operation === 'percentage'
        ) {
          visiblesHeaders.recurring_percentage = index;
        } else if (
          header.type === 'flat' &&
          header.operation === 'percentage'
        ) {
          visiblesHeaders.flat_percentage = index;
        } else if (header.type === 'flat' && header.operation === 'money') {
          visiblesHeaders.flat_money = index;
        } else if (header.type === 'range' && header.operation === 'low') {
          visiblesHeaders.range_low = index;
        } else if (header.type === 'range' && header.operation === 'high') {
          visiblesHeaders.range_high = index;
        }
      } else {
        header.visible = false;
      }
    });
    const classShowSm = headers.length > 5 ? 'show-sm' : 'hide show-sm';
    return (
      <Card.Group key="pricingtab-card" centered className={classShowSm}>
        {cards?.body
          .filter((e: any) =>
            acceptance.includes(e.columns[0].value.toLowerCase())
          )
          .map(
            (rowr: any, index: number) =>
              this.hideCardAcceptanceTable(
                rowr.columns[0].value,
                serviceType
              ) && (
                <Card key={`pricingtab-card-${index}`}>
                  <Card.Content>
                    <Card.Header>
                      <div className="header-centered">
                        <Image
                          style={
                            serviceType === 'MGM' ||
                            rowr.columns[0].value.toLowerCase() === 'amex' ||
                            rowr.columns[0].value.toLowerCase() === 'mastercard'
                              ? { height: '28px' }
                              : rowr.columns[0].value.toLowerCase() === 'web'
                              ? { height: '50px' }
                              : { height: '14px' }
                          }
                          src={
                            serviceType === 'MGM'
                              ? mgmIcon
                              : rowr.columns[0].value.toLowerCase() === 'web'
                              ? achIcon
                              : rowr.columns[0].value.toLowerCase() === 'visa'
                              ? visa
                              : rowr.columns[0].value.toLowerCase() ===
                                'mastercard'
                              ? mastercard
                              : rowr.columns[0].value.toLowerCase() ===
                                'discover'
                              ? discover
                              : amex
                          }
                          rounded
                        />
                      </div>
                    </Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      <>
                        <div className="pricing-card-content">
                          {Object.entries(visiblesHeaders || [])
                            .flat()
                            .includes('flat_money') &&
                            (visiblesHeaders?.flat_percentage ||
                              visiblesHeaders.flat_money) && (
                              <div className="flat">
                                {visiblesHeaders?.flat_percentage && (
                                  <div>
                                    <h2>
                                      {parseFloat(
                                        rowr.columns[
                                          visiblesHeaders?.flat_percentage
                                        ]?.value || 0
                                      ).toFixed(2)}{' '}
                                      %
                                    </h2>
                                  </div>
                                )}
                                {visiblesHeaders.flat_money && (
                                  <div>
                                    {
                                      <span className="small-small grey">
                                        + $
                                        {parseFloat(
                                          rowr.columns[
                                            visiblesHeaders.flat_money
                                          ]?.value || 0
                                        ).toFixed(2)}{' '}
                                        per Auth
                                      </span>
                                    }
                                  </div>
                                )}
                              </div>
                            )}
                          {!Object.entries(visiblesHeaders || [])
                            .flat()
                            .includes('flat_money') && (
                            <>
                              {(visiblesHeaders?.onetime_percenntage ||
                                visiblesHeaders?.onetime_money) && (
                                <div className="onetime">
                                  <span>One Time</span>
                                  {visiblesHeaders?.onetime_percenntage && (
                                    <div>
                                      <h2>
                                        {parseFloat(
                                          rowr.columns[
                                            visiblesHeaders?.onetime_percenntage
                                          ]?.value || 0
                                        ).toFixed(2)}{' '}
                                        %
                                      </h2>
                                    </div>
                                  )}
                                  {visiblesHeaders?.onetime_money && (
                                    <div>
                                      {
                                        <span className="small-small grey">
                                          + $
                                          {parseFloat(
                                            rowr.columns[
                                              visiblesHeaders?.onetime_money
                                            ]?.value || 0
                                          ).toFixed(2)}{' '}
                                          per Auth
                                        </span>
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                              {(visiblesHeaders?.recurring_percentage ||
                                visiblesHeaders?.recurring_money) && (
                                <div className="recurring">
                                  <span>Recurring</span>
                                  {visiblesHeaders?.recurring_percentage && (
                                    <div>
                                      <h2>
                                        {parseFloat(
                                          rowr.columns[
                                            visiblesHeaders
                                              ?.recurring_percentage
                                          ]?.value || 0
                                        ).toFixed(2)}{' '}
                                        %
                                      </h2>
                                    </div>
                                  )}
                                  {visiblesHeaders?.recurring_money && (
                                    <div>
                                      <span className="small-small grey">
                                        + $
                                        {parseFloat(
                                          rowr.columns[
                                            visiblesHeaders?.recurring_money
                                          ]?.value || 0
                                        ).toFixed(2)}{' '}
                                        per Auth
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {(Object.entries(visiblesHeaders || [])
                          .flat()
                          .includes('range_low') ||
                          Object.entries(visiblesHeaders || [])
                            .flat()
                            .includes('range_high')) && (
                          <div className="pricing-card-low-high">
                            <span>Low/High pay range</span>
                            <div className="low-high-range">
                              {Object.entries(visiblesHeaders || [])
                                .flat()
                                .includes('range_low') &&
                                visiblesHeaders?.range_low && (
                                  <div>
                                    <Icon
                                      size="small"
                                      name="angle double up"
                                      color="green"
                                    />
                                    <span>
                                      ${' '}
                                      {parseFloat(
                                        rowr.columns[visiblesHeaders?.range_low]
                                          ?.value || 0
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                )}
                              {Object.entries(visiblesHeaders || [])
                                .flat()
                                .includes('range_high') &&
                                visiblesHeaders?.range_high && (
                                  <div>
                                    <Icon
                                      size="small"
                                      name="angle double down"
                                      color="red"
                                    />
                                    <span>
                                      ${' '}
                                      {parseFloat(
                                        rowr.columns[
                                          visiblesHeaders?.range_high
                                        ]?.value || 0
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </>
                    </Card.Description>
                  </Card.Content>
                </Card>
              )
          )}
      </Card.Group>
    );
  }

  hideCardAcceptance(): boolean {
    return (
      (this.serviceData && this.serviceData?.card === null) ||
      (this.serviceData && this.serviceData?.card?.acceptance === null) ||
      (this.serviceData &&
        this.serviceData?.card?.acceptance?.visible === false)
    );
  }
  getRegionByCode(code: string): string {
    if (code?.toUpperCase() === 'CA') {
      return 'Canada';
    }
    return 'United States';
  }
  getCardAcceptance(): any {
    if (this.hideCardAcceptance()) {
      return;
    }
    const rows: any[] = [];
    return (
      <>
        <Grid.Row columns={2} className="mb-3">
          <h6>Processing Region</h6>
          <small>
            {this.getRegionByCode(
              this.serviceData?.card?.processing_region?.value
            )}
          </small>
          <br />
          {rows}
        </Grid.Row>
        <Grid.Row columns={2} className="mb-3">
          <h6>Card Acceptance</h6>
          <small>Integrated per transaction fee for card acceptance.</small>
          <br />
          {rows}
        </Grid.Row>
      </>
    );
  }

  hideCardPricing(): boolean {
    return (
      !this.serviceData?.card?.acceptance ||
      !this.serviceData?.card?.price?.header
    );
  }

  hideCardAcceptanceTable(typeCard: any, serviceType?: string): any {
    switch (typeCard.toUpperCase()) {
      case 'AMEX':
        return this.serviceData?.card?.acceptance.amex?.visible;
      case 'DISCOVER':
        return this.serviceData?.card?.acceptance.discover?.visible;
      case 'MASTERCARD':
        return this.serviceData?.card?.acceptance.mastercard?.visible;
      case 'VISA':
        return this.serviceData?.card?.acceptance.visa?.visible;
      case 'WEB':
        return serviceType === 'ACH'
          ? this.serviceData?.ach?.acceptance.web?.visible
          : this.serviceData?.mgm?.acceptance.web?.visible;
      case 'CCD':
        return serviceType === 'ACH'
          ? this.serviceData?.ach?.acceptance.ccd?.visible
          : this.serviceData?.mgm?.acceptance.ccd?.visible;
      case 'PPD':
        return serviceType === 'ACH'
          ? this.serviceData?.ach?.acceptance.ppd?.visible
          : this.serviceData?.mgm?.acceptance.ppd?.visible;
      default:
        return false;
    }
  }

  getVisiblePricing2Type(type: string): any {
    const pricing: any = this.serviceData;
    const pricingType: number = Number(pricing[type].pricingType?.value);
    const fieldsPricing = [
      'percentxAuth',
      'amountxAuth',
      'percentRecurring',
      'amountRecurring',
      'lowPayRange',
      'highPayRange',
    ];
    if (pricingType === 5 && type === 'ach') {
      fieldsPricing.splice(2, 2);
    }
    switch (pricingType) {
      case 1:
        return {
          label: 'ICP',
          type: 1,
          visibility: fieldsPricing.map(
            (e) => pricing[type][`${type}ICP_${e}`]?.value
          ),
        };
      case 2:
        return {
          label: 'Flat',
          type: 2,
          visibility: fieldsPricing.map(
            (e) => pricing[type][`${type}Flat_${e}`]?.value
          ),
        };
      case 3:
        return {
          label: 'PassThrough',
          type: 3,
          visibility: fieldsPricing.map((e) => {
            const value =
              pricing[type][
                `${type}Pass${type === 'card' ? 'Through' : ''}_${e}`
              ]?.value;
            return value === undefined ? 'true' : value;
          }),
        };
      case 4:
        return {
          label: 'Flat_PassThrough',
          type: 4,
          visibility: fieldsPricing.map(
            (e) =>
              pricing[type][
                `${type}Pass${type === 'card' ? 'Through' : ''}_${e}`
              ]?.value
          ),
          // keep this code for future use when api will be sent 2 types of pricing
          // visibility: [
          //   fieldsPricing.map((e) => pricing[type][`${type}Flat_${e}`]?.value),
          //   fieldsPricing.map((e) => pricing[type][`${type}PassThrough_${e}`]?.value)
          // ]
        };
      case 5:
        return {
          label: 'Absorb',
          type: 5,
          visibility: fieldsPricing.map(
            (e) => pricing[type][`${type}Absorb_${e}`]?.value || 'true'
          ),
        };
      case 6:
        return {
          label: 'Absorb_PassThrough',
          type: 6,
          visibility: fieldsPricing.map(
            (e) => pricing[type][`${type}PassThrough_${e}`]?.value
          ),
          // keep this code for future use when api will be sent 2 types of pricing
          // visibility: [
          //   fieldsPricing.map((e) => pricing[type][`${type}Absorb_${e}`]?.value),
          //   fieldsPricing.map((e) => pricing[type][`${type}PassThrough_${e}`]?.value)
          // ]
        };
      default:
        return '';
    }
  }

  getNonVisibleColumns(type: string): any {
    return this.getVisiblePricing2Type(type)
      .visibility.filter((e: any) => e !== undefined)
      .reduce((acc: number[], value: string, index: number) => {
        if (value === 'false' || value === '') {
          acc.push(index + 1);
        }
        return acc;
      }, []);
  }

  getCardPricing(): any {
    if (this.hideCardPricing()) return;
    const acceptance = Object.entries(this.serviceData.card.acceptance || [])
      .filter((r) => r[1] !== null)
      .map((e) => e[0].toLowerCase());
    const visibleRanges = [''];
    const percentageColumns = this.serviceData.card.price.header.columns
      .map((e: any, index: number) => (e.value.startsWith('%') ? index : null))
      .filter((r: any) => r !== null);
    const notVisibleColumns = this.getNonVisibleColumns('card');
    const headers = this.serviceData?.card?.price?.header?.columns;
    const classHideSm =
      headers.length > 5
        ? 'hide pricing-container'
        : 'hide-sm pricing-container';
    return (
      this.serviceData &&
      this.serviceData?.card !== null &&
      this.serviceData?.card?.price !== null &&
      (this.serviceData?.card?.acceptance?.amex?.visible ||
        this.serviceData?.card?.acceptance?.discover?.visible ||
        this.serviceData?.card?.acceptance?.mastercard?.visible ||
        this.serviceData?.card?.acceptance?.visa?.visible) &&
      this.serviceData.card?.price?.body?.length > 0 && (
        <>
          <h6 className="mb-2">Pricing</h6>
          <Table className={classHideSm}>
            {this.serviceData.card.price.header !== null && (
              <Table.Header>
                <Table.Row>
                  {this.serviceData.card.price.header?.columns
                    .filter(
                      (e: any) =>
                        e?.visible === true || !visibleRanges.includes(e.value)
                    )
                    .map(
                      (colr: any, index: number) =>
                        !notVisibleColumns.includes(index) && (
                          <Table.HeaderCell key={index}>
                            {capitalizeFirstLetterOfEachWord(colr.value)}
                          </Table.HeaderCell>
                        )
                    )}
                </Table.Row>
              </Table.Header>
            )}
            {this.serviceData.card?.price?.body !== null && (
              <Table.Body>
                {this.serviceData?.card?.price?.body
                  .filter((e: any) =>
                    acceptance.includes(e.columns[0].value.toLowerCase())
                  )
                  .map(
                    (rowr: any, index: number) =>
                      this.hideCardAcceptanceTable(rowr.columns[0].value) && (
                        <Table.Row key={index}>
                          {rowr.columns
                            .filter((colF: any) => colF?.visible === true)
                            .map(
                              (colr: any, ic: number) =>
                                !notVisibleColumns.includes(ic) && (
                                  <Table.Cell key={ic}>
                                    {(ic === 0 && (
                                      <div className="card-brands ">
                                        <div>
                                          <Image
                                            style={
                                              colr.value.toLowerCase() ===
                                                'amex' ||
                                              colr.value.toLowerCase() ===
                                                'mastercard'
                                                ? {
                                                    height: '28px',
                                                  }
                                                : {
                                                    height: '14px',
                                                  }
                                            }
                                            src={
                                              colr.value.toLowerCase() === 'web'
                                                ? achIcon
                                                : colr.value.toLowerCase() ===
                                                  'visa'
                                                ? visa
                                                : colr.value.toLowerCase() ===
                                                  'mastercard'
                                                ? mastercard
                                                : colr.value.toLowerCase() ===
                                                  'discover'
                                                ? discover
                                                : amex
                                            }
                                            rounded
                                          />
                                        </div>
                                      </div>
                                    )) ||
                                      (percentageColumns.includes(ic)
                                        ? `${parseFloat(
                                            colr.value || 0
                                          ).toFixed(2)}%`
                                        : `$${parseFloat(
                                            colr.value || 0
                                          ).toFixed(2)}`)}
                                  </Table.Cell>
                                )
                            )}
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            )}
          </Table>

          {this.getTabPricing(
            this.serviceData?.card?.price,
            acceptance,
            notVisibleColumns,
            'CARD'
          )}
        </>
      )
    );
  }

  hideCardFee(): boolean {
    return !(
      this.serviceData?.card?.fees?.header?.columns?.filter(
        (col: any) => col?.visible
      ).length > 0
    );
  }

  getCardFee(): any {
    // let visiblePriceColumns = 99
    if (this.hideCardFee()) {
      return;
    }
    if (this.serviceData?.card?.price?.header) {
      // visiblePriceColumns = this.serviceData.services.card.price.header.columns.length - 2
    }
    return (
      this.serviceData &&
      this.serviceData.card !== null &&
      this.serviceData?.card?.fees !== null &&
      this.serviceData?.card?.fees?.body?.length > 0 && (
        <>
          <h6 className="mt-2 mb-2">Fees</h6>
          <Table unstackable className="pricing-container">
            {this.serviceData.card.fees.header !== null && (
              <Table.Header>
                <Table.Row>
                  {this.serviceData.card.fees.header.columns.map(
                    (colr: any, index: number) => (
                      <Table.HeaderCell key={index}>
                        {colr.value === 'Fee' ? 'Occurrence Fees' : colr.value}
                      </Table.HeaderCell>
                    )
                  )}
                </Table.Row>
              </Table.Header>
            )}
            {this.serviceData.card?.fees?.body !== null && (
              <Table.Body>
                {this.serviceData.card?.fees?.body.map(
                  (rowr: any, index: number) => (
                    <Table.Row key={index}>
                      {rowr.columns
                        .filter((colF: any) => colF?.visible === true)
                        .map((colr: any, ic: number) => {
                          return (
                            <Table.Cell key={ic}>
                              {ic === 1
                                ? `$${parseFloat(colr.value || 0).toFixed(2)}`
                                : `${colr.value}` || 0}
                            </Table.Cell>
                          );
                        })}
                    </Table.Row>
                  )
                )}
              </Table.Body>
            )}
          </Table>
        </>
      )
    );
  }

  hideAchAcceptance(): boolean {
    return (
      (this.serviceData && this.serviceData?.ach === null) ||
      (this.serviceData && this.serviceData?.ach?.acceptance === null) ||
      (this.serviceData && this.serviceData?.ach?.acceptance?.visible === false)
    );
  }

  getAchAcceptance(): any {
    if (this.hideAchAcceptance()) return;
    // var rows = [];
    // const acceptance = this.serviceData.ach.acceptance;
    // if (this.serviceData && this.serviceData?.ach?.acceptance?.web) {
    //   rows.push(<Checkbox label='Web' readOnly checked={acceptance?.web?.value} />);
    // }
    return (
      <Grid.Row columns={2} className="mb-3">
        <h6 className="mb-2">ACH Acceptance</h6>
        <small className="mb-2 small">
          Integrated per transaction fee for ACH debits and credits.
        </small>
        <br />
        {/* {rows} */}
      </Grid.Row>
    );
  }

  hideAchPricing(): boolean {
    return (
      !this.serviceData?.ach?.acceptance ||
      !this.serviceData?.ach?.price?.header
    );
  }

  getAchPricing(): any {
    if (this.hideAchPricing()) return;
    const acceptance = Object.entries(this.serviceData.ach.acceptance || [])
      .filter((r) => r[0] === 'web')
      .map((e) => e[0].toLowerCase());
    const visibleRanges = [''];
    const percentageColumns = this.serviceData.ach.price.header.columns
      .map((e: any, index: number) => (e.value.startsWith('%') ? index : null))
      .filter((r: any) => r !== null);

    const notVisibleColumns = this.getNonVisibleColumns('ach');
    const headers = this.serviceData?.ach?.price?.header?.columns;
    const classHideSm =
      headers.length > 5
        ? 'hide pricing-container'
        : 'hide-sm pricing-container';
    return (
      this.serviceData &&
      this.serviceData.ach !== null &&
      this.serviceData.ach.price !== null &&
      (this.serviceData?.ach?.acceptance?.ccd?.visible ||
        this.serviceData?.ach?.acceptance?.ppd?.visible ||
        this.serviceData?.ach?.acceptance?.web?.visible) &&
      this.serviceData?.ach?.price?.body?.length > 0 && (
        <>
          <h6 className="mb-2">Pricing</h6>
          <Table className={classHideSm}>
            {this.serviceData.ach.price.header !== null && (
              <Table.Header>
                <Table.Row>
                  {this.serviceData.ach.price.header.columns
                    .filter(
                      (e: any) =>
                        e?.visible === true || !visibleRanges.includes(e.value)
                    )
                    .map(
                      (colr: any, index: number) =>
                        !notVisibleColumns.includes(index) && (
                          <Table.HeaderCell key={index}>
                            {colr.value === 'Card'
                              ? 'ACH'
                              : capitalizeFirstLetterOfEachWord(colr.value)}
                          </Table.HeaderCell>
                        )
                    )}
                </Table.Row>
              </Table.Header>
            )}
            {this.serviceData.ach?.price?.body !== null && (
              <Table.Body>
                {this.serviceData.ach?.price?.body
                  .filter((e: any) =>
                    acceptance.includes(e.columns[0].value.toLowerCase())
                  )
                  .map((rowr: any, index: number) => (
                    <Table.Row key={index}>
                      {rowr.columns
                        .filter((colF: any) => colF?.visible === true)
                        .map(
                          (colr: any, ic: number) =>
                            !notVisibleColumns.includes(ic) && (
                              <Table.Cell key={ic}>
                                {(ic === 0 && (
                                  <div className="card-brands ">
                                    <div>
                                      <Image
                                        alt=""
                                        style={{
                                          height: '28px',
                                          marginLeft: 0,
                                        }}
                                        src={achIcon}
                                      />
                                    </div>
                                  </div>
                                )) ||
                                  (percentageColumns.includes(ic)
                                    ? `${parseFloat(colr.value || 0).toFixed(
                                        2
                                      )}%`
                                    : `$${parseFloat(colr.value || 0).toFixed(
                                        2
                                      )}`)}
                              </Table.Cell>
                            )
                        )}
                    </Table.Row>
                  ))}
              </Table.Body>
            )}
          </Table>
          {this.getTabPricing(
            this.serviceData?.ach?.price,
            acceptance,
            notVisibleColumns,
            'ACH'
          )}
        </>
      )
    );
  }

  hideAchFee(): boolean {
    return !(
      this.serviceData?.ach?.fees?.header?.columns?.filter(
        (col: any) => col?.visible
      ).length > 0
    );
  }

  getAchFee(): any {
    let visibleAchColumns = 99;
    if (this.hideAchFee()) {
      return;
    }
    if (this.serviceData?.ach?.price?.header) {
      visibleAchColumns = this.serviceData.ach.price.header.columns.length - 2;
    }
    return (
      this.serviceData &&
      this.serviceData?.ach !== null &&
      this.serviceData?.ach?.fees !== null &&
      this.serviceData?.ach?.fees?.body?.length > 0 && (
        <>
          <h6 className="mt-2 mb-2">Fees</h6>
          <Table unstackable className="pricing-container">
            {this.serviceData.ach.fees.header !== null && (
              <Table.Header>
                <Table.Row>
                  {this.serviceData.ach.fees.header.columns.map(
                    (colr: any, index: number) => (
                      <Table.HeaderCell key={index}>
                        {colr.value === 'Fee' ? 'Occurrence Fees' : colr.value}
                      </Table.HeaderCell>
                    )
                  )}
                </Table.Row>
              </Table.Header>
            )}
            {this.serviceData.ach?.fees?.body !== null && (
              <Table.Body>
                {this.serviceData.ach?.fees?.body.map(
                  (rowr: any, index: number) => (
                    <Table.Row key={index}>
                      {rowr.columns
                        .filter((colF: any) => colF?.visible === true)
                        .map((colr: any, ic: number) => (
                          <Table.Cell
                            style={
                              ic === 0 && visibleAchColumns === 3
                                ? {
                                    width: '62%',
                                  }
                                : ic === 0 && visibleAchColumns === 5
                                ? {
                                    width: '77%',
                                  }
                                : ic === 0 && visibleAchColumns > 5
                                ? {
                                    width: '90%',
                                  }
                                : {}
                            }
                            key={ic}
                          >
                            {ic === 1
                              ? `$${parseFloat(colr.value || 0).toFixed(2)}`
                              : `${colr.value}` || 0}
                          </Table.Cell>
                        ))}
                    </Table.Row>
                  )
                )}
              </Table.Body>
            )}
          </Table>
        </>
      )
    );
  }

  hideMgmAcceptance(): boolean {
    return (
      (this.serviceData && this.serviceData?.mgm === null) ||
      (this.serviceData && this.serviceData?.mgm?.acceptance === null) ||
      (this.serviceData && this.serviceData?.mgm?.acceptance?.visible === false)
    );
  }

  getMgmAcceptance(): any {
    if (this.hideMgmAcceptance()) return;
    // var rows = [];
    // const acceptance = this.serviceData.mgm.acceptance;
    // if (this.serviceData && this.serviceData?.mgm?.acceptance?.web) {
    //   rows.push(<Checkbox label='Web' readOnly checked={acceptance?.web?.value} />);
    // }
    return (
      <Grid.Row columns={2} className="mb-3">
        <h6 className="mb-2">Managed Payables Acceptance</h6>
        <small className="mb-2 small">
          Integrated per transaction fee for Managed Payables debits and
          credits.
        </small>
        <br />
        {/* {rows} */}
      </Grid.Row>
    );
  }

  hideMgmPricing(): boolean {
    return (
      !this.serviceData?.mgm?.acceptance ||
      !this.serviceData?.mgm?.price?.header
    );
  }

  getMgmPricing(): any {
    if (this.hideMgmPricing()) return;
    const acceptance = Object.entries(this.serviceData.mgm.acceptance || [])
      .filter((r) => r[0] === 'web')
      .map((e) => e[0].toLowerCase());
    const visibleRanges = [''];
    const percentageColumns = this.serviceData.mgm.price.header.columns
      .map((e: any, index: number) => (e.value.startsWith('%') ? index : null))
      .filter((r: any) => r !== null);
    const notVisibleColumns = this.serviceData.mgm.price.header.columns
      .map((e: any, index: number) =>
        visibleRanges.includes(e.value) ? index : null
      )
      .filter((r: any) => r !== null);
    const headers = this.serviceData?.mgm?.price?.header?.columns;
    const classHideSm = headers.length > 5 ? 'hide' : 'hide-sm';
    return (
      this.serviceData &&
      this.serviceData.mgm !== null &&
      this.serviceData.mgm.price !== null &&
      (this.serviceData?.mgm?.acceptance?.ccd?.visible ||
        this.serviceData?.mgm?.acceptance?.ppd?.visible ||
        this.serviceData?.mgm?.acceptance?.web?.visible) &&
      this.serviceData?.mgm?.price?.body?.length > 0 && (
        <>
          <h6 className="mb-2">Pricing</h6>
          <Table className={classHideSm}>
            {this.serviceData.mgm.price.header !== null && (
              <Table.Header>
                <Table.Row>
                  {this.serviceData.mgm.price.header.columns
                    .filter(
                      (e: any) =>
                        e?.visible === true || !visibleRanges.includes(e.value)
                    )
                    .map((colr: any, index: number) => (
                      <Table.HeaderCell key={index}>
                        {colr.value === 'Card' ? 'MGM' : colr.value}
                      </Table.HeaderCell>
                    ))}
                </Table.Row>
              </Table.Header>
            )}
            {this.serviceData.mgm?.price?.body !== null && (
              <Table.Body>
                {this.serviceData.mgm?.price?.body
                  .filter((e: any) =>
                    acceptance.includes(e.columns[0].value.toLowerCase())
                  )
                  .map((rowr: any, index: number) => (
                    <Table.Row key={index}>
                      {rowr.columns
                        .filter((colF: any) => colF?.visible === true)
                        .map(
                          (colr: any, ic: number) =>
                            !notVisibleColumns.includes(ic) && (
                              <Table.Cell key={ic}>
                                {(ic === 0 && (
                                  <div className="card-brands ">
                                    <div>
                                      <Image
                                        alt="mnage"
                                        style={{
                                          height: '28px',
                                          marginLeft: 0,
                                        }}
                                        src={mgmIcon}
                                      />
                                    </div>
                                  </div>
                                )) ||
                                  (percentageColumns.includes(ic)
                                    ? `${parseFloat(colr.value || 0).toFixed(
                                        2
                                      )}%`
                                    : `$${parseFloat(colr.value || 0).toFixed(
                                        2
                                      )}`)}
                              </Table.Cell>
                            )
                        )}
                    </Table.Row>
                  ))}
              </Table.Body>
            )}
          </Table>
          {this.getTabPricing(
            this.serviceData?.mgm?.price,
            acceptance,
            notVisibleColumns,
            'MGM'
          )}
        </>
      )
    );
  }

  hideMgmFee(): boolean {
    return !(
      this.serviceData?.mgm?.fees?.header?.columns?.filter(
        (col: any) => col?.visible
      ).length > 0
    );
  }

  getMgmFee(): any {
    let visibleMgmColumns = 99;
    if (this.hideMgmFee()) {
      return;
    }
    if (this.serviceData?.mgm?.price?.header) {
      visibleMgmColumns = this.serviceData.mgm.price.header.columns.length - 2;
    }
    return (
      this.serviceData &&
      this.serviceData?.mgm !== null &&
      this.serviceData?.mgm?.fees !== null &&
      this.serviceData?.mgm?.fees?.body?.length > 0 && (
        <>
          <h6 className="mt-2 mb-2">Fees</h6>
          <Table unstackable>
            {this.serviceData.mgm.fees.header !== null && (
              <Table.Header>
                <Table.Row>
                  {this.serviceData.mgm.fees.header.columns.map(
                    (colr: any, index: number) => (
                      <Table.HeaderCell key={index}>
                        {colr.value === 'Fee' ? 'Occurrence Fees' : colr.value}
                      </Table.HeaderCell>
                    )
                  )}
                </Table.Row>
              </Table.Header>
            )}
            {this.serviceData.mgm?.fees?.body !== null && (
              <Table.Body>
                {this.serviceData.mgm?.fees?.body.map(
                  (rowr: any, index: number) => (
                    <Table.Row key={index}>
                      {rowr.columns
                        .filter((colF: any) => colF?.visible === true)
                        .map((colr: any, ic: number) => (
                          <Table.Cell
                            style={
                              ic === 0 && visibleMgmColumns === 3
                                ? {
                                    width: '62%',
                                  }
                                : ic === 0 && visibleMgmColumns === 5
                                ? {
                                    width: '77%',
                                  }
                                : ic === 0 && visibleMgmColumns > 5
                                ? {
                                    width: '90%',
                                  }
                                : {}
                            }
                            key={ic}
                          >
                            {ic === 1
                              ? `$${parseFloat(colr.value || 0).toFixed(2)}`
                              : `${colr.value}` || 0}
                          </Table.Cell>
                        ))}
                    </Table.Row>
                  )
                )}
              </Table.Body>
            )}
          </Table>
        </>
      )
    );
  }

  render(): React.ReactNode {
    const showCard =
      Object.entries(this.serviceData || []).length > 0 &&
      (!this.hideCardAcceptance() ||
        !this.hideCardPricing() ||
        !this.hideCardFee() ||
        !this.hideAchAcceptance() ||
        !this.hideAchPricing() ||
        !this.hideAchFee() ||
        !this.hideMgmAcceptance() ||
        !this.hideMgmPricing() ||
        !this.hideMgmFee());
    return (
      <>
        {showCard && (
          <Transition duration={400} visible={this.state?.visible}>
            <Container>
              <div className="mb-4">
                <h3>Services and Pricing</h3>
                <small className="small mb-4 dark-grey">
                  Below you will find the rates associated with your Payment
                  Processing Account.
                </small>
              </div>
              <Container>
                {this.getCardAcceptance()}
                {this.getCardPricing()}
                {this.getCardFee()}
                <br />
                {this.getAchAcceptance()}
                {this.getAchPricing()}
                {this.getAchFee()}
                <br />
                {this.getMgmAcceptance()}
                {this.getMgmPricing()}
                {this.getMgmFee()}
              </Container>
            </Container>
          </Transition>
        )}
        {Object.entries(this.serviceData || []).length === 0 && (
          <Transition duration={400} visible={this.state?.visible}>
            <Container>
              <div className="mb-4">
                <h3>It does not have pricing services available</h3>
              </div>
            </Container>
          </Transition>
        )}
      </>
    );
  }
}

export default inject('boarding')(observer(Pricing));
